import React, {useState} from 'react';
import {graphql} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LayoutInner from "../../components/layoutInner";
import {keys} from "lodash"
import * as PATHS from "../../utils/paths";

const styles = theme => ({
    root: {},
    heroText: {
        fontSize: 32,
        lineHeight: "48px",
        fontWeight: 400,
        marginBottom: 16
    },
    subheroText: {
        fontSize: 18,
        lineHeight: "32px",
        fontWeight: "regular",
        marginBottom: 16
    },
});



const FORM_URL = "https://us-central1-three-is-a-pattern.cloudfunctions.net/contactFormSignup"

const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const [submitSuccess, setSubmitSuccess] = useState(false)

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title={"3iap Workshops"} location={{pathname: PATHS.WORKSHOPS}}/>

            <LayoutInner>

                <h1>Available workshops:</h1>
                {/*<p className={classes.subheroText}>How can 3iap help? Can we..</p>*/}
                <ul className={classes.subheroText}>
                    <li><a href={PATHS.WORKSHOPS_EQUITY_DATAVIZ}>Equity-Focused Dataviz</a></li>
                </ul>

            </LayoutInner>
        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
